import { axiosInstance } from "../config";

//零售线上审方列表
export const pendingPresList = (data) => {
  return axiosInstance.post(`/api/hosp/online-pharmacist/get-pres-list`, data);
};
//零售对应问诊处方信息
export const consuDetail = (data) => {
  return axiosInstance.post(`/api/hosp/other-platforms/re-online-meds`, data);
};
//药师审方
export const phaSignatureOnline = (data) => {
  return axiosInstance.post(
    `/faas/hosp/online-pharmacist/online-pha-signature`,
    data
  );
};
// 查询线上平台药师待审处方
export const onlinePendingPresList = (data) => {
  return axiosInstance.post(
    "/api/hosp/wzpharmacist/pending-prescription-list",
    data
  );
};
//查询药店详情
export const storeDetailsRequest = (pharmacyId, userId) => {
  return axiosInstance.get(
    `/api/hosp/pharmacy/details?pharmacyId=${pharmacyId}&userId=${userId}`
  );
};
