import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AuthRoute from "../component/authroute/authroute";
import Home from "../container/Home/index";
import homeduty from "../container/Home/homeduty";
import dutydetail from "../container/Home/homeduty/dutydetail";
import dutyaccount from "../container/Home/homeduty/dutyaccount";
import dutyconsultation from "../container/Home/homeduty/dutyconsultation";

import dutyrefuse from "../container/Home/homeduty/dutyrefuse";

import Mine from "../container/Mine/index";
import Collection from "../container/Mine/collection/index";

// import Orderdetail from '../container/Mine/order/orderdetail/index'

import ElectronicInvoice from "../container/Mine/electronicInvoice/index";

import Coupon from "../container/Mine/coupon/index";
import Viewrecord from "../container/Mine/viewrecord/index";
import resign from "../container/Mine/resign/index";
import Set from "../container/Mine/set/index";
import Login from "../container/Login/login";
import Loginduty from "../container/Login/Loginduty";

// import Register from '../container/Register/register'
import Seckill from "../container/Home/Activity/seckill";
import electronicsignature from "../container/Home/electronicsignature/index";
import addpass from "../container/Mine/set/addpass/index";

import Departmenttype from "../container/Home/Departmenttype/index";
import Shopcart from "../application/cart/index";
import Fillorder from "../application/cart/fillorder";
import security from "../container/Mine/set/security";
import uploaddoc from "../application/uploaddoc";
import setserver from "../container/Home/setserver";
import downapp from "../container/Home/downapp";

import pharmacistreviewer from "../container/Home/pharmacistreviewer/index";

import videoprescription from "../container/Home/videoprescription";
import account from "../container/Mine/account";
import record from "../container/Mine/record";
import certificatemanagement from "../container/Mine/certificatemanagement";
import userinfo from "../container/Mine/userinfo";
import docterpic from "../container/Mine/userinfo/docterpic";
import privacyagreement from "../application/privacyagreement";
import agrenagreement from "../application/agrenagreement";

// 药店药师注册审核
import uploadpha from "../application/uploadpha";
// 药店药师签名
import gosign from "../container/Home/gosign/index";
// 药店药师重新签名
import resignpha from "../container/Mine/resignpha/index";
import OnlineLIst from "../container/Home/online/OnlineList/OnlineLIst";
import OnlinePres from "../container/Home/online/onlinePres/OnlinePres";

class SubRouter extends Component {
  render() {
    return (
      <div>
        <AuthRoute></AuthRoute>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/homeduty" component={homeduty}></Route>
          <Route exact path="/dutydetail" component={dutydetail}></Route>
          <Route exact path="/dutyaccount" component={dutyaccount}></Route>
          <Route
            exact
            path="/dutyconsultation"
            component={dutyconsultation}
          ></Route>
          {/* 拒诊入口 */}
          <Route exact path="/dutyrefuse" component={dutyrefuse}></Route>
          <Route path="/setserver" component={setserver}></Route>
          <Route path="/downapp" component={downapp}></Route>
          <Route path="/privacyagreement" component={privacyagreement} />
          <Route path="/agrenagreement" component={agrenagreement} />
          <Route path="/shopcart" component={Shopcart}></Route>
          <Route path="/fillorder" component={Fillorder}></Route>
          <Route
            path="/pharmacistreviewer"
            component={pharmacistreviewer}
          ></Route>

          <Route
            path="/videoprescription"
            component={videoprescription}
          ></Route>
          <Route path="/uploaddoc" component={uploaddoc}></Route>

          {/* 药店药师注册审核 */}
          <Route path="/uploadpha" component={uploadpha}></Route>

          <Route path="/departmenttype" component={Departmenttype}></Route>

          <Route exact path="/mine" component={Mine}></Route>
          <Route exact path="/mine/account" component={account}></Route>
          <Route exact path="/mine/record" component={record}></Route>
          <Route
            exact
            path="/mine/certificatemanagement"
            component={certificatemanagement}
          ></Route>
          <Route exact path="/mine/userinfo" component={userinfo}></Route>
          <Route
            exact
            path="/mine/userinfo/docterpic"
            component={docterpic}
          ></Route>
          <Route exact path="/mine/set/security" component={security}></Route>
          <Route path="/mine/collection" component={Collection}></Route>

          <Route
            path="/mine/electronicInvoice"
            component={ElectronicInvoice}
          ></Route>
          <Route path="/mine/coupon" component={Coupon}></Route>
          <Route path="/mine/viewrecord" component={Viewrecord}></Route>
          <Route path="/mine/resign" component={resign}></Route>
          {/* <Route path='/mine/set' component={Set}></Route> */}

          <Route path="/mine/set" exact component={Set}></Route>
          <Route path="/mine/set/addpass" component={addpass}></Route>
          <Route path="/login" exact component={Login}></Route>
          <Route path="/Loginduty" exact component={Loginduty}></Route>

          {/* <Route path='/register' component={Register}></Route> */}
          <Route path="/seckill" component={Seckill}></Route>
          <Route
            path="/electronicsignature"
            component={electronicsignature}
          ></Route>
          {/* 药店药师签名 */}
          <Route path="/gosign" component={gosign}></Route>
          {/* 药店药师重新签名 */}
          <Route path="/mine/resignpha" component={resignpha}></Route>
          <Route path="/comment" component={Comment}></Route>

          {/* 线上审方 */}
          <Route exact path="/online" component={OnlineLIst}></Route>
          <Route exact path="/onlinepres/:id" component={OnlinePres}></Route>
        </Switch>
      </div>
    );
  }
}

export default SubRouter;
