import icon1 from "../../../../static/img/yy/fanhuihei.png";
import React, { useEffect, useState } from "react";
import icon5 from "../../../../static/img/successimg.png";
import icon4 from "../../../../static/img/bhimg.png";
import PdfViewer from "../../../../component/PdfView/PdfViewer";
import {
  consuDetail,
  pendingPresList,
  phaSignatureOnline,
} from "../../../../api/request/retail";
import { caUrl, baseUrl, jumpUrl } from "../../../../publicurl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import getUrl from "../../../../component/GetUrlParam";
import "./onlinepres.less";
import { isCapwd } from "../../../../api/hospitalapi/pharmacist";
import { getuserinfo } from "../../../../api/hospitalapi/login";
import {
  pendingPrescriptionList,
  prescribingPrice,
} from "../../../../api/hospitalapi/offline";
import { imgToCanvas, dealParams, getBase64 } from "../../../../api/config";
import {
  caAuthentication,
  getToken,
  addToken,
  tokenSupervision,
  uploadRecipe,
  updateEprescribing,
} from "../../../../api/hospitalapi/upload";
import { Toast, SpinLoading, Modal, Radio, Space } from "antd-mobile-v5";
import { debounce } from "lodash";

export default function OnlinePres(props) {
  const [file, setFile] = useState(null);
  const [presDetail, setPresDetail] = useState({});
  const { id } = useParams();
  const [vis, setVis] = useState(false);
  const [rejVis, setRejVis] = useState(false);
  const [rejReason, setRejReason] = useState(null);
  const [pwdVis, setPwdVis] = useState(false);
  const [pwd, setPwd] = useState("");
  const [pwdCode, setPwdCode] = useState(false); //是否已经记住密码
  const docterPhone = localStorage.getItem("pharmacistPhone");
  const pharmacistId = localStorage.getItem("pharmacistId");
  const pid = getUrl("pid", props.location.search);
  const [caid, setCaId] = useState(0);
  const [pharmacistzz, setPharmacistzz] = useState({}); //药师资质
  const [pharmacistDetails, setPharmacistDetails] = useState({});
  const [phaPrice, setphaPrice] = useState();
  const [flag, setFlag] = useState(1);
  const doctorId = localStorage.getItem("pharmacistId");
  const radioList = [
    { value: "药品超量", label: "药品超量" },
    { value: "药品用法用量超量", label: "药品用法用量超量" },
    { value: "重复用药", label: "重复用药" },
    { value: "疾病错误", label: "疾病错误" },
    { value: "患者年龄不符合", label: "患者年龄不符合" },
    { value: "患者信息错误", label: "患者信息错误" },
    { value: "其他", label: "其他" },
  ];
  const [otherReasonVis, setOtherReasonVis] = useState(false);
  const back = () => {
    props.history.goBack();
  };
  const getpresDetail = () => {
    consuDetail({
      cid: id,
    }).then((pres) => {
      if (pres.code == 1 && pres.data && pres.data[2]) {
        setPresDetail(pres.data[2][0]);
        let url = pres.data[2][0].shop_signature_url
          ? pres.data[2][0].shop_signature_url
          : pres.data[2][0].prescribing_url
          ? pres.data[2][0].prescribing_url
          : pres.data[2][0].original_prescribing;
        setFile(url);
      }
    });
  };
  //   获取药师信息
  const phaInfo = async () => {
    if (docterPhone) {
      let res0 = await isCapwd({ docterId: pharmacistId });
      if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
        if (res0.data[0][0].doc_ca_pwd) {
          setPwd(res0.data[0][0].doc_ca_pwd);
          setPwdCode(true);
        } else {
          setPwdCode(false);
        }
      }
      let res = await getuserinfo({ docterPhone });
      // console.log(res)
      if (res && res.data && res.data[0]) {
        setPharmacistDetails(res.data[0][0]);
        setPharmacistzz(res.data[1][0]);
        setCaId(res.data[0][0].ca_id);
      }
      let res1 = await prescribingPrice({ docterId: pharmacistId });
      // console.log(res1)
      if (res1 && res1.data && res1.data[0] && res1.data[0].length) {
        setphaPrice(res1.data[0][0].price);
      }
    }
  };
  const onReject = () => {
    if (
      !pharmacistDetails.docter_autograph ||
      pharmacistDetails.docter_autograph == ""
    ) {
      Toast.show("请先设置签名");
      return;
    }
    if (rejReason == "" || !rejReason) {
      Toast.show("请输入理由");
      return;
    }
    if (presDetail.original_prescribing) {
      passwordSure();
    } else {
      Toast.show("无效处方单", 1);
    }
  };
  const onSuccesss = () => {
    if (
      !pharmacistDetails.docter_autograph ||
      pharmacistDetails.docter_autograph == ""
    ) {
      Toast.show("请先设置签名");
      return;
    }
    if (presDetail.original_prescribing) {
      if (!pwdCode) {
        setPwdVis(true);
      } else {
        passwordSure();
      }
    } else {
      Toast.show("无效处方单", 1);
    }
  };
  const rejReasonChange = (e) => {
    let value = e.target.value.replace(/\s*/g, "");
    setRejReason(value);
  };
  const passwordValue = (e) => {
    let value = e.target.value.replace(/\s*/g, "");
    setPwd(value);
  };
  //确认密码
  const passwordSure = debounce(async () => {
    if (pwd || rejReason) {
      setPwdVis(false);
      setRejVis(false);
      setOtherReasonVis(false);
      if (pwd) {
        setFlag(2);
      }
      // this.getPdf()
      let phaSignaturetime = Date.parse(new Date()) / 1000;
      let data = {
        prescribingId: pid,
        consultationId: id,
        isRetail: presDetail ? presDetail.is_retail : null,
        docterId: pharmacistId,
        password: pwd,
        knowcode: pwdCode,
        phaSignaturetime,
        refusereson: rejReason ? rejReason : null,
        pharmacistId1: pharmacistId,
        phy_staff_id: 0,
        phy_pharmacist_id: 0,
      };
      // console.log(data, "审方传递的参数");
      // return data;
      let res1 = await phaSignatureOnline(data);
      if (res1 && res1.code == 1) {
        if (res1.data.pha_pharmacist_id) {
          // Toast.info('此处方单已被审核，无法继续审核',1)
          Toast.show("此处方笺已审核", 1);
          props.history.push("/online");
        } else {
          if (res1.data.docEdit && res1.data.docEdit == 1) {
            Toast.show("此处方单已被医生修改，为失效处方单，无法继续审核", 1);
            props.history.push("/online");
          } else {
            if (res1.data && res1.data.result_msg) {
              if (res1.data.result_msg == "云证书获取失败[PIN校验失败]") {
                setPwd("");
                setFlag(1);
                failPassword();
              } else {
                Toast.show("签章失败，请重新签名后再进行审方");
              }
            } else {
              Toast.show("审核通过", 1);
              setFlag(1);
              // 审核通过之后查询处方列表是否有数据，有的话就出现弹窗，没有就到待审处方列表
              // 查询属于中药药师还是西药药师
              let res99 = await pendingPresList({
                isPha: pharmacistDetails.is_pha,
                doctorId,
                page: 1,
                size: 20,
              });
              // console.log('页面中待审处方列表',res99);
              if (res99.data[0].length > 0) {
                let phatime = Date.parse(new Date()) / 1000;
                let pid = res99.data[0][0].prescribingId;
                let docterId = res99.data[0][0].docterId;
                let cid = res99.data[0][0].consultation_id;
                Modal.confirm({
                  title: "审核提醒",
                  content: "有新的处方等待审核，请药师快去审核",
                  confirmText: "去审核",
                  cancelText: "知道了",
                  onCancel: () => {
                    props.history.push("/online");
                  },
                  onConfirm: () => {
                    props.history.replace(`/onlinepres/${cid}?pid=${pid}`);
                    // 刷新页面
                    window.location.reload();
                  },
                });
              } else {
                props.history.push("/online");
              }
            }
          }
        }
      }
    } else {
      Toast.show("请先输入密码");
      return;
    }
  }, 800);
  const handleChangeRadio = (e) => {
    if (e != "其他") {
      setRejReason(e);
      setOtherReasonVis(false);
    } else {
      setRejReason("");
      setOtherReasonVis(true);
    }
  };
  //密码错误，ca验证失败
  const failPassword = async () => {
    Modal.confirm({
      title: "密码错误",
      content: "忘记密码可修改密码",
      confirmText: "重新输入",
      cancelText: "忘记密码",
      onCancel: () => {
        let data0 = {
          entityId: caid,
          busiType: "5",
          redirectUrl: baseUrl + "/faas/hosp/scca/callback",
          redirectPageUrl:
            jumpUrl +
            `/index.html#/pharmacistreviewer?prescribingId=${pid}&docterId=${doctorId}`,
          province: "四川省",
          locality: "德阳市",
          orgName: "云益健互联网医院",
        };

        let { toSign, params } = dealParams(data0);
        caAuthentication({
          url: caUrl,
          toSign,
          FormData: params,
        }).then((data) => {
          if (data && data.data && data.data.success === false) {
            Toast.info(data.data.result_msg);
          } else if (data && data.data) {
            window.location.href = data.data.body;
          }
        });
      },
      onConfirm: () => {
        setPwdVis(true);
        setFlag(1);
      },
    });
  };
  useEffect(() => {
    getpresDetail();
    phaInfo();
    // if (getUrl("f", props.location.search) == "view") {
    //   setVis(false);
    // } else if (getUrl("f", props.location.search) == "edit") {
    //   setVis(true);
    // }
  }, []);
  return (
    <div>
      <div className="box-topimg">
        <img className="top-img" src={icon1} alt="" onClick={back} />
        <span className="title-middle">处方详情</span>
      </div>
      <PdfViewer file={file} />
      {presDetail.prescribing_state == 0 && (
        <div className="edit-box">
          <p
            className="e-refuse"
            onClick={() => {
              setRejVis(true);
            }}
          >
            驳回
          </p>
          <p
            className="e-success"
            onClick={() => {
              onSuccesss();
            }}
          >
            通过
          </p>
        </div>
      )}
      {rejVis && (
        <>
          <div className="cancel-cons-wrap-radio">
            <div className="refusewz-edit" onClick={(e) => e.stopPropagation()}>
              <p className="refusewz-tips">请选择驳回理由</p>
              <div className="refuse-radio-box">
                <Radio.Group onChange={handleChangeRadio}>
                  <Space direction="vertical">
                    {radioList.length &&
                      radioList.map((item, index) => (
                        <Radio key={index} value={item.value}>
                          {item.label}
                        </Radio>
                      ))}
                  </Space>
                </Radio.Group>
              </div>

              {otherReasonVis && (
                <textarea
                  rows={5}
                  placeholder="请输入驳回理由(50字以内)"
                  maxLength={50}
                  defaultValue={rejReason}
                  onChange={(e) => rejReasonChange(e)}
                />
              )}

              <p className="refusewz-button">
                <span
                  onClick={() => {
                    setRejVis(false);
                    setRejReason("");
                    setOtherReasonVis(false);
                  }}
                >
                  取消
                </span>
                <span onClick={() => onReject()}>确认</span>
              </p>
            </div>
          </div>
        </>
      )}
      {pwdVis && (
        <div className="bohui-outer">
          <div className="bohui">
            <p className="customstyle title">请输入密码</p>
            <p className="content">
              <span className="customstyle">密码：</span>
              <input
                className="customstyle"
                id="iosinput"
                type="password"
                placeholder="请输入密码"
                maxLength={20}
                defaultValue={pwd}
                onChange={(e) => passwordValue(e)}
              />
            </p>
            <p className="content-jizhu">
              <input
                type="radio"
                checked={pwdCode}
                onClick={() => setPwdCode((prev) => !prev)}
                onChange={() => {}}
              />
              <span className="customstyle">记住密码，下次无需输入密码</span>
            </p>
            <p className="button">
              <span
                className="customstyle"
                onClick={() => {
                  setPwdVis(false);
                  setPwdCode(false);
                  setPwd("");
                }}
              >
                取消
              </span>
              <span className="customstyle" onClick={() => passwordSure()}>
                确认
              </span>
            </p>
          </div>
        </div>
      )}
      {(presDetail.reason || presDetail.pha_refu_reson) && (
        <p className="rej-reason">
          驳回理由：{presDetail.reason || presDetail.pha_refu_reson}
        </p>
      )}
      {presDetail.prescribing_state == 1 &&
      presDetail.prescring_pha_state != 2 ? (
        <img className="pres-img" src={icon5} />
      ) : presDetail.prescribing_state == -1 ||
        presDetail.prescring_pha_state == 2 ? (
        <img className="pres-img" src={icon4} />
      ) : null}
      {flag == 2 ? (
        <div className="chat-center-outer">
          <SpinLoading />
          {/*<p>跳转中...</p>*/}
        </div>
      ) : null}
    </div>
  );
}
