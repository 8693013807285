import React, { useState, useEffect, useRef } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "./pdf.less";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PDFViewer({ file }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const touchStartRef = useRef(null);
  const pdfFile =
    file && file.startsWith("http://")
      ? file.replace("http://", "https://")
      : file;
  // console.log(pdfFile, "文件");
  function onDocumentLoadSuccess(document) {
    const { numPages } = document;
    setNumPages(numPages);
  }

  useEffect(() => {
    document.querySelectorAll(".react-pdf__Page__canvas").forEach((canvas) => {
      canvas.style.width = "100%";
      canvas.style.height = "auto";
    });
  }, [numPages]);

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleTouchStart = (event) => {
    // console.log(event.touches[0], "触摸起始点");
    touchStartRef.current = event.touches[0].clientX;
  };

  const handleTouchEnd = (event) => {
    // console.log(event.changedTouches[0], "触摸结束点");
    // console.log(touchStartRef, "触摸ref");
    const touchEndX = event.changedTouches[0].clientX;
    if (touchStartRef.current - touchEndX > 50) {
      nextPage();
    } else if (touchEndX - touchStartRef.current > 50) {
      prevPage();
    }
  };

  return (
    <div
      className="pdf-cont"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>
      {numPages > 1 && (
        <div className="pagination">
          <button onClick={prevPage} disabled={pageNumber <= 1}>
            {"<"}
          </button>
          <span>
            {pageNumber} / {numPages}
          </span>
          <button onClick={nextPage} disabled={pageNumber >= numPages}>
            {">"}
          </button>
        </div>
      )}
    </div>
  );
}
