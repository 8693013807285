import React, { useEffect, useState } from "react";
import FootNav from "../../../../component/FootNav";
import "./onlinelist.less";
import { Tabs, Badge } from "antd-mobile";
import { pendingPresList } from "../../../../api/request/retail";
import res from "antd-mobile-icons/es/AaOutline";
import { DatePicker, Toast } from "antd-mobile-v5";
import Nodata from "../../../../component/Nodata";
import { getuserinfo } from "../../../../api/hospitalapi/login";

export default function OnlineList(props) {
  const [tabs, setTabs] = useState([
    { title: "待审处方", key: "wait", badge: "." },
    { title: "已审处方", key: "final", badge: "" },
  ]);
  const formatDate = (date) => {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    // Ensure month and day are two digits
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };
  const currentDate = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const [topDate, setTopDate] = useState(formatDate(currentDate));
  const [page, setPage] = useState(1);
  const [waitPres, setWaitPres] = useState([]); //待审处方
  const [finalPres, setFinalPres] = useState([]); //已审处方
  const [startVis, setStartVis] = useState(false);
  const [endVis, setEndVis] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [loading, setLoading] = useState(false);
  const [reqLock, setReqlock] = useState(false);
  const [timer, setTimer] = useState(null); //轮询状态
  const [doctor, setDoctor] = useState({});
  const doctorId = localStorage.getItem("pharmacistId");
  let isPha;
  const getwaitpresList = async (p) => {
    let phaRes = await getuserinfo({
      docterPhone: localStorage.getItem("pharmacistPhone"),
    });
    if (phaRes.code == 1 && phaRes.data && phaRes.data[0].length) {
      setDoctor(phaRes.data[0][0]);
      isPha = phaRes.data[0][0].is_pha;
    }
    let params = {
      page: p ? p : page,
      size: 20,
      doctorId,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      isPha,
    };

    let res = await pendingPresList(params);
    //   console.log(res);
    if (res.code == 1) {
      let data = [];
      let finaldata = [];
      if (res.data && res.data[0] && res.data[2].length) {
        data = res.data[0].map((i) => {
          i.arrmeds = [];
          res.data[2].forEach((j) => {
            if (j.prescribing_id == i.prescribingId) {
              i.arrmeds.push(j);
            }
          });
          return i;
        });
      }
      if (res.data && res.data[1] && res.data[2].length) {
        finaldata = res.data[1].map((i) => {
          i.arrmeds = [];
          res.data[2].forEach((j) => {
            if (j.prescribing_id == i.prescribingId) {
              i.arrmeds.push(j);
            }
          });
          return i;
        });
      }
      setPage((prev) => prev + 1);
      // console.log(finaldata, "已完成数组");
      // setWaitPres(data);
      // setFinalPres(finaldata);
      if (res.data.length == 4) {
        setTabs((prev) => [
          { ...prev[0], badge: res.data[2][0].nums },
          { ...prev[1], badge: res.data[3][0].finalNums },
        ]);
        setLoading(true);
        setReqlock(true);
      } else {
        setLoading(false);
        setReqlock(false);
        setTabs((prev) => [
          { ...prev[0], badge: res.data[3][0].nums },
          { ...prev[1], badge: res.data[4][0].finalNums },
        ]);
      }

      setWaitPres((prev) => [...prev, ...data]);
      setFinalPres((prev) => [...prev, ...finaldata]);
    }

    // pendingPresList(params).then((res) => {
    //   //   console.log(res);
    //   if (res.code == 1) {
    //     let data = [];
    //     let finaldata = [];
    //     if (res.data && res.data[0] && res.data[2].length) {
    //       data = res.data[0].map((i) => {
    //         i.arrmeds = [];
    //         res.data[2].forEach((j) => {
    //           if (j.prescribing_id == i.prescribingId) {
    //             i.arrmeds.push(j);
    //           }
    //         });
    //         return i;
    //       });
    //     }
    //     if (res.data && res.data[1] && res.data[2].length) {
    //       finaldata = res.data[1].map((i) => {
    //         i.arrmeds = [];
    //         res.data[2].forEach((j) => {
    //           if (j.prescribing_id == i.prescribingId) {
    //             i.arrmeds.push(j);
    //           }
    //         });
    //         return i;
    //       });
    //     }
    //     setPage((prev) => prev + 1);
    //     // console.log(finaldata, "已完成数组");
    //     // setWaitPres(data);
    //     // setFinalPres(finaldata);
    //     if (res.data.length == 4) {
    //       setTabs((prev) => [
    //         { ...prev[0], badge: res.data[2][0].nums },
    //         { ...prev[1], badge: res.data[3][0].finalNums },
    //       ]);
    //       setLoading(true);
    //       setReqlock(true);
    //     } else {
    //       setLoading(false);
    //       setReqlock(false);
    //       setTabs((prev) => [
    //         { ...prev[0], badge: res.data[3][0].nums },
    //         { ...prev[1], badge: res.data[4][0].finalNums },
    //       ]);
    //     }

    //     setWaitPres((prev) => [...prev, ...data]);
    //     setFinalPres((prev) => [...prev, ...finaldata]);
    //   }
    // });
  };
  // 触底更新数据
  const onscroll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(
    //   e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight,
    //   "滚动"
    // );
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      5
    ) {
      if (!loading && !reqLock) {
        setLoading(true);
        getwaitpresList();
      }
    }
  };
  const toDetail = (id, pid) => {
    props.history.push(`/onlinepres/${id}?pid=${pid}`);
  };
  const handleStartConfirm = (date) => {
    setStartDate(date);
    setStartVis(false);
    setEndVis(true);
    if (date > endDate && endDate != null) {
      setEndDate(date);
    }
  };
  const handleEndConfirm = (date) => {
    setEndVis(false);
    const timeDiff = date - startDate;
    const dayDiff = timeDiff / (1000 * 60 * 60 * 24); // 转换为天数

    // if (dayDiff >= 30) {
    //   // 如果超过30天，给出提示
    //   Toast.show("只支持查询30天，请重新选择查询的起始时间");
    //   return;
    // }
    if (date < startDate || startDate == null) {
      setStartDate(date);
      setEndDate(date);
    } else {
      setEndDate(date);
    }
  };
  // useEffect(() => {
  //   getwaitpresList();
  // }, []);
  useEffect(() => {
    const timeDiff = endDate - startDate;
    const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (dayDiff > 30) {
      Toast.show("只支持查询30天，请重新选择查询的起始时间");
      return;
    } else {
      setPage(1);
      // setFinalPres([]);
      // setWaitPres([]);
      getwaitpresList(1);
    }
  }, [endDate]);
  // useEffect(() => {
  //   const newTimer = setInterval(() => {
  //     setPage(1);
  //     setFinalPres([]);
  //     setWaitPres([]);
  //     getwaitpresList(1);
  //   }, 5000);
  //   setTimer(newTimer);
  //   return () => {
  //     clearInterval(newTimer);
  //   };
  // }, []);
  const presRender = (props) => {
    return (
      <div>
        {props.length > 0 ? (
          props.map((item, idx) => (
            <div className="list-box" key={idx}>
              <div className="platform">
                <p>
                  <span>处方单号：</span>
                  {item.prescribingNumber}
                </p>
                <p>
                  {item.prescribingState == 1 ? (
                    <span className="suc">审核通过</span>
                  ) : item.prescribingState == -1 ? (
                    <span className="error">审核未通过</span>
                  ) : null}
                  <span className="flag">平台</span>
                </p>
              </div>
              <p>
                <span>患者姓名：</span>
                {item.patientName}
              </p>
              <p>
                <span>药品需求：</span>
                {item.arrmeds &&
                  item.arrmeds.map((medItem, index) => (
                    <span key={medItem.e_medicinal_id}>
                      {" "}
                      <span
                        className={
                          medItem.supervisionType == "西药" ? "wmed" : "cmed"
                        }
                      >
                        ({medItem.supervisionType})
                      </span>
                      <span style={{ color: "#3D3D3D" }}>
                        {medItem.medComName} {""} {medItem.medSpec} x
                        {medItem.medQuantity}
                        {index < item.arrmeds.length - 1 && ";"}
                      </span>
                    </span>
                  ))}
              </p>
              <p>
                <span>提交店铺：</span>
                {item.pharmacy_name}
              </p>
              {(item.prescribingState == -1 ||
                item.prescring_pha_state == 2) && (
                <p className="refuse">
                  <span>驳回理由：</span>
                  {item.reason || item.pha_refu_reson}
                </p>
              )}
              <div className="bottom-box">
                <span>{item.createTime}</span>
                {item.prescribingState == 0 ? (
                  <p
                    onClick={() => {
                      toDetail(item.consultation_id, item.prescribingId);
                    }}
                  >
                    审方
                  </p>
                ) : (
                  <p
                    onClick={() => {
                      toDetail(item.consultation_id, item.prescribingId);
                    }}
                  >
                    查看处方
                  </p>
                )}
              </div>
            </div>
          ))
        ) : (
          <Nodata type="审方" />
        )}
      </div>
    );
  };
  const onlineRender = (key) => {
    return (
      <div>
        {key == "wait" ? (
          <div className="wait-box" onScroll={(e) => onscroll(e)}>
            {presRender(waitPres)}
          </div>
        ) : key == "final" ? (
          <div className="final-box" onScroll={(e) => onscroll(e)}>
            <div className="date-box">
              <p>
                <span
                  onClick={() => {
                    setStartVis(true);
                  }}
                >
                  {startDate ? formatDate(startDate) : topDate}
                </span>
                &nbsp;至&nbsp;
                <span
                  onClick={() => {
                    setEndVis(true);
                  }}
                >
                  {endDate ? formatDate(endDate) : topDate}
                </span>
                <span></span>{" "}
              </p>
            </div>
            {/* 开始日期选择 */}
            <DatePicker
              mode="date"
              title="选择开始时间"
              visible={startVis}
              value={startDate}
              max={currentDate}
              onConfirm={handleStartConfirm}
              onClose={() => setStartVis(false)}
            />
            {/* 结束日期选择 */}
            <DatePicker
              mode="date"
              title="选择结束时间"
              visible={endVis}
              value={endDate}
              max={currentDate}
              onConfirm={handleEndConfirm}
              onClose={() => setEndVis(false)}
            />
            {presRender(finalPres)}
          </div>
        ) : null}
      </div>
    );
  };
  return (
    <div className="online-box">
      <Tabs
        tabs={tabs.map((tab) => ({
          title: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ height: "14px", lineHeight: "14px" }}>
                {tab.title}
              </span>
              {tab.badge > 0 && (
                <Badge
                  text={tab.badge}
                  style={{
                    marginLeft: 8,
                    top: 0,
                    height: "14px",
                    lineHeight: "14px",
                  }}
                />
              )}
            </div>
          ),
          key: tab.key,
        }))}
        tabBarActiveTextColor="#1F87FF"
        tabBarBackgroundColor="#fff"
        tabBarUnderlineStyle={{
          border: "2px #1A73E8 solid",
          transform: "scaleX(0.15)",
        }}
      >
        {tabs.map((tab) => (
          <div
            // style={{ height: "90vh", overflow: "auto" }}
            // onScroll={(e) => onscroll(e)}
            key={tab.key}
          >
            {onlineRender(tab.key)}
          </div>
        ))}
      </Tabs>
      <FootNav />
    </div>
  );
}
