import React, { Component } from 'react'
// import icon1 from "../../../static/img/yy/fanhuihei.png"
import { Badge, SideBar } from 'antd-mobile-v5'
import "./style.less"
import Debounce from 'lodash.debounce';
import SignatureCanvas from 'react-signature-canvas'
import getUrl from '../../../component/GetUrlParam'
import icon1 from '../../../static/img/yy/qianming.png'
import icon2 from '../../../static/img/yy/over.png'
import icon3 from '../../../static/img/yy/del.png'
import {updateCa} from "../../../api/hospitalapi/home";
import {updateDocterAutograph} from "../../../api/hospitalapi/mine";
import {imgToCanvas,rotateBase64Img,dealImage,dealParams} from '../../../api/config'
import {zipImg} from "../../../component/zipImage";
import {uploadImg} from "../../../api/request/upload";
import {getuserinfo} from "../../../api/hospitalapi/login";
import {baseUrl, jumpUrl} from "../../../publicurl";
import {testca,cacallback,addPhaMenber,addPhaSign} from "../../../api/hospitalapi/upload";
import {Toast} from "antd-mobile";
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            trimmedDataURL: null,

            docterId:localStorage.getItem("pharmacistId"),
            docterPhone : localStorage.getItem("pharmacistPhone"),
            img:'',
            caIspass:2,
            pharmacyId:localStorage.getItem("pharmacyId"),
            pha_pharmacist:localStorage.getItem("name"),
            signcode:0, //0:还未签名
        }
    }
    componentDidMount = async () => {
        const {pha_pharmacist}= this.state
        // console.log(pha_pharmacist);
    }
    trim = Debounce(() => {
        const { pha_pharmacist,docterId,pharmacyId,signcode } = this.state
        // console.log(pha_pharmacist);
        console.log(11111111111);
        
        let aaa = this.sigCanvas.isEmpty();
        let img0=''
        let img1=''
        if (aaa){
            Toast.info('请先签名')
        }else {
            this.setState({
                trimmedDataURL: this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")
            }, () => {
                let res0 = this.sigCanvas.getTrimmedCanvas().toDataURL("image/png")

                //顺时针旋转90度
                let res1 = rotateBase64Img(res0, 90, callback)

                function callback(val) {
                    img1 = val
                }

                setTimeout(() => {
                    let a = dealImage(img1, callback1)
                    // console.log(a)

                    function callback1(val) {
                        //base64转png图片
                        let res2 = imgToCanvas(val)
                        let file = res2
                        // if (file.type=="image/png"||file.type=="image/jpeg"||file.type=="image/jpg") {
                        zipImg(file).then(res => {
                            // console.log(res)
                            let formData = new FormData()
                            formData.append('file', res)
                            // console.log(formData)
                            uploadImg(formData).then(res1 => {
                                // console.log(res1)
                                if (res1 && res1.code == 2) {
                                    img0 = res1.data
                                }
                                // Toast.hide()
                            }).catch(err => {
                                // Toast.hide()
                            })
                        })
                    }
                }, 500)
                setTimeout(() => {
                    // console.log(img0);
                    if (img0) {
                        addPhaSign({
                            pharmacist_img: img0,
                            docter_id: docterId,
                            pharmacyId: pharmacyId,
                            pha_pharmacist: pha_pharmacist
                        }).then(res3 => {
                            // console.log(res3)
                            if (res3 && res3.code == 1) {
                                Toast.info('签名保存成功')
                                this.props.history.push("/")
                            }
                        })
                    }
                }, 1000)

            });
        }
    },800);
    render() {
        let { trimmedDataURL } = this.state;
        return (
            <div className='electronicsignature'>
                <div className='electronicsignature-top'>
                    手写签名
                </div>
                <div className='electronicsignature-body'>
                    <div className='electronicsignature-body-left'>
                        <img src={icon1}/>
                    </div>
                    <div className='electronicsignature-body-center'>
                        <SignatureCanvas
                            penColor="#000"  // 笔刷颜色
                            minWidth={5} // 笔刷粗细
                            maxWidth={7}
                            ref={(ref) => { this.sigCanvas = ref }} //用于调用插件的API
                            canvasProps={{width: window.outerWidth*.7, height: window.outerHeight*.7, style:{position:'absolute',top:'18vh',left:'15vw',backgroundColor:'#F3F3F3', },
                                className: 'sigCanvas'}} />
                    </div>
                    <div className='electronicsignature-body-right'>
                        <img src={icon2} onClick={this.trim}/>
                        <img src={icon3} onClick={()=>this.sigCanvas.clear()} />
                    </div>
                </div>
                <img id="img" src={trimmedDataURL}/>
            </div>
        )
    }
}
