import React, { Component } from 'react'
import {DatePicker, Space, Steps, } from "antd-mobile-v5"
import { Tabs,Switch } from 'antd-mobile'
import { Link, withRouter } from 'react-router-dom'
import FootNav from '../../../component/FootNav'
import { getCount,getDocjznum ,getDayDocjznum,updateCa} from "../../../api/hospitalapi/home"
import { getuserinfo } from "../../../api/hospitalapi/login"
import { getnewslist } from "../../../api/hospitalapi/news"
import '../../../static/fonts/font_3834206_ru0l96toyc/iconfont.css'
import './style.less'
import "../../../static/css/iconfont.css"
import getUrl from '../../../component/GetUrlParam'
import icon1 from "../../../static/img/duty/is-jz.png"
import icon2 from "../../../static/img/duty/is-sf.png"
import icon6 from "../../../static/img/duty/is-jjwz.png"
import icon4 from "../../../static/img/yy/ts.png"
import icon5 from "../../../static/img/vedio-icon.png"
import failicon from "../../../static/img/fail-icon.png"
import checkfailicon from "../../../static/img/check-fail-icon.png"
import noseeicon from "../../../static/img/no-see-icon.png"
import {CheckCircleFill, ClockCircleFill,CloseCircleFill} from "antd-mobile-icons";
import {addOpenid} from "../../../api/hospitalapi/offline";
import {dutyList,selectDutymenber} from "../../../api/hospitalapi/duty";
import {Modal,Toast} from "antd-mobile";
import moment from 'moment'
import Nodata from "../../../component/Nodata";
import {caUrl,jumpUrl,baseUrl} from "../../../publicurl"
const { Step } = Steps
const { alert } = Modal
class Platform extends Component {
    constructor(props) {
        super(props)
        this.state = {
            yslist: [],
            docter: '',
            docterAvatar: localStorage.getItem("dutyAvatar"),
            docterPhone: localStorage.getItem("dutyPhone"),
            count1: 0,//待接问诊
            count2: 0,//待审处方,
            count3: 0,//已接未开处方,
            status: "",
            list: "",
            tabs: [{title: '待接问诊'}, {title: '未开方'}, {title: '待审处方'}],
            docterId: localStorage.getItem("dutyId"),
            list1:[], //待接问诊
            list2:[],//待审处方
            list3:[],//已接未开处方
            code:1,
            timer:'',
            dutymenber:[],
        }
    }
    componentWillUnmount = () => {
        const {timer, } = this.state
        window.clearInterval(timer)
    }
    componentDidMount = async () => {
        const {docterId, } = this.state
        // console.log(moment('2022-10-27').add(1, 'year').diff( moment(), 'day') )

        const docterPhone = localStorage.getItem("dutyPhone")

        if (getUrl('openid',window.location.search)) {
            let res=await addOpenid({openid:getUrl('openid', window.location.search),docterId:localStorage.getItem("dutyId")})
            // console.log(res)
        }
        if (docterId){
            this.allList()
            // this.dutyMenber()
        }

        
    }
    allList=async ()=>{
        const {timer} = this.state
        window.clearInterval(timer)
        let res=await dutyList({})
        // console.log('列表',res)
        if (res.code==1){
            this.setState({
                list1:res.data[0], //待接问诊
                list2:res.data[1],
                list3:res.data[2],
                count1:res.data[3][0].count1,
                count2:res.data[4][0].count2,
                count3:res.data[5][0].count3,
                tabs: [{title: '待接问诊'+' ('+res.data[3][0].count1+')'}, {title: '未开方'+' ('+res.data[5][0].count3+')'}, {title: '待审处方'+' ('+res.data[4][0].count2+')'}],
            })
            const newTimer = window.setInterval(() => {
                this.setState({
                    timer: newTimer
                }, () => {
                    this.allList()
                    // this.dutyMenber()
                })

            }, 5000);
        }
    }

    // dutyMenber=async()=>{
    //     const {timer} = this.state
    //     window.clearInterval(timer)

    //     // 查询今日值班人员
    //     const today = new Date();
    //     const year = today.getFullYear();
    //     const month = (today.getMonth() + 1).toString().padStart(2, '0');
    //     const day = today.getDate().toString().padStart(2, '0');
    //     const todayDate = `${year}-${month}-${day}`;
    //     console.log(todayDate,'71=====');
    //     let res01 = await selectDutymenber({dutytime: todayDate})
    //     console.log(res01,'67=====');
    //     this.setState({
    //         dutymenber:res01.data[0]
    //     })
    // }

    tologin = () => {
        const docterPhone = localStorage.getItem("dutyPhone")
        if (!localStorage.getItem("dutyId")) {
            this.props.history.push("/Loginduty")
        }
    }

    renderContent = (tab, index) => {
        const {list1, list2,list3,count2,docterPhone,visible4} = this.state
        return (
            <div>
                {/*{docterPhone ? <span className={index==0?'one':'one one1'}>({count1})</span> : null}*/}
                {/*{docterPhone ? <span className={index==1?'two':'two two1'}>({count2})</span> : null}*/}
                {index == 0 ? <div className='Loginduty-one-outer'>
                        {list1.length ? list1.map((item,i) => (
                            <div key={i} className='Loginduty-cart' onClick={()=>{
                                this.props.history.push(`/dutydetail?consultationId=${item.consultationId}&dutycode=${1}`)
                            }}>
                                <p className='Loginduty-ids'>
                                    <p style={{ display:'flex',alignItems:'center'}}>
                                        <span>患者：</span>
                                        <span className='number'>{item.patientName} ({item.patientSex==1?'男':'女'}/{item.patientAge}岁)</span>
                                        {item.patientIdcardNum?<i className='iconfont icon-emishenfenzheng'/>:null}
                                        {(item.isAi == '2' && item.aiCheck == '2') ? <img src={noseeicon} className='fail-icon' loading='lazy'></img> : null}
                                    </p>
                                    {item.consultationType == '3' ? <>
                                        <p className='vedio-box'>
                                            <img className='vedio-icon' src={icon5} />
                                        </p>
                                    </> : null}
                                </p>
                                {/* <p className='Loginduty-id'>
                                    <span>是否含中药饮片：</span>
                                    <span className='number'>{item.containsNine=="是"?'是':'否'}</span>
                                </p> */}
                                <p className='Loginduty-id'>
                                    <span>科室：</span>
                                    <span className='number' style={{ display:'inline-block',marginLeft:'5px'}}>{item.is_classify_id == 1 ? '中医科' : null}</span>
                                    <span className='number' style={{ display:'inline-block',marginLeft:'5px'}}>{item.is_internal == 1 ? '内科' : null}</span>
                                    <span className='number' style={{ display:'inline-block',marginLeft:'5px'}}>{item.is_surgery == 1 ? '外科' : null}</span>
                                </p>
                                <p className='Loginduty-id'>
                                    <span>指定医生：</span>
                                    <span className='number'>{item.is_appoint_doctor==1 ? item.docter_name : '无'}</span>
                                </p>
                                <p className='Loginduty-topay'>
                                    提交时间：{item.createTime}
                                </p>
                            </div>
                        )) : <Nodata type='问诊'/>}
                    </div> :
                    index == 1 ?
                        <div className='Loginduty-one-outer' >
                            {list3.length ? list3.map((item,i) => (
                                <div key={i} className='Loginduty-cart' onClick={()=>{
                                    this.props.history.push(`/dutydetail?consultationId=${item.consultationId}&dutycode=${2}`)
                                }}>
                                    <p className='Loginduty-ids'>
                                        <p style={{display:'flex',alignItems:'center'}}>
                                            <span>患者：</span>
                                            <span className='number'>{item.patientName} ({item.patientSex==1?'男':'女'}/{item.patientAge}岁)</span>
                                            {item.patientIdcardNum?<i className='iconfont icon-emishenfenzheng'/>:null}
                                            {item.isAi==1?<i className='iconfont icon-jiqiren1' style={{color:'#0EC278'}}/>:null}
                                            {item.isAiRe==1?<i className='iconfont icon-jiqiren1' style={{color:'#f727ff'}}/>:null}
                                            {(item.isAi==1 && item.failReason) ? <img src={failicon} className='fail-icon' loading='lazy'></img> : null}
                                        </p>
                                        {item.consultationType == '3' ? <>
                                            <p className='vedio-box'>
                                                <img className='vedio-icon' src={icon5} />
                                            </p>
                                        </> : null}
                                    </p>
                                    {/* <p className='Loginduty-id'>
                                        <span>是否含中药饮片：</span>
                                        <span className='number'>{item.containsNine=="是"?'是':'否'}</span>
                                    </p> */}
                                    <p className='Loginduty-id'>
                                        <span>科室：</span>
                                        <span className='number'>{item.docter_department}</span>
                                    </p>
                                    <p className='Loginduty-id'>
                                        <span>指定医生：</span>
                                        <span className='number'>{item.is_appoint_doctor==1 ? item.docter_name : '无'}</span>
                                    </p>
                                    <p className='Loginduty-topay'>
                                        提交时间：{item.createTime}
                                    </p>
                                </div>
                            )) : <Nodata type='问诊'/>}
                        </div>
                    :
                        index == 2 ? <>
                                <div className='Loginduty-one-outer' >
                                    {list2.length ? list2.map((item,j) => (
                                        <div key={j} className='Loginduty-cart' onClick={()=>{
                                            this.props.history.push(`/dutydetail?consultationId=${item.consultationId}&prescribingId=${item.prescribingId}&dutycode=${2}`)
                                        }}>
                                            <p className='Loginduty-number'>
                                                <p>
                                                    <span>处方单号：</span>
                                                    <span className='number'>{item.prescribingNumber}</span>
                                                </p>
                                                <p style={{display:'flex',alignItems:'center'}}>
                                                    {item.prescribing_state == '1' && item.prescring_pha_state == '0' ? (<span style={{color:'#FF8D30',marginRight:'5px'}}>等待药店药师审核</span>) : null}
                                                    {item.consultationType == '3' ? <>
                                                    <span className='vedio-box'>
                                                        <img className='vedio-icon' src={icon5} />
                                                    </span>
                                                </> : null}
                                                </p>                                                
                                            </p>
                                            <p className='Loginduty-id'>
                                                <span>患者：</span>
                                                <span className='number'>{item.patientName} ({item.patientSex==1?'男':'女'}/{item.patientAge}岁)</span>
                                                {item.patientIdcardNum?<i className='iconfont icon-emishenfenzheng'/>:null}
                                                {item.isAi==1?<i className='iconfont icon-jiqiren1' style={{color:'#0EC278'}}/>:null}
                                                {item.isAiRe==1?<i className='iconfont icon-jiqiren1' style={{color:'#f727ff'}}/>:null}
                                                {(item.checkFailReason && item.prescribing_state == '0') ? <img src={checkfailicon} className='fail-icon' loading='lazy'></img> : null}
                                            </p>
                                                
                                            <p className='Loginduty-id'>
                                                <span>指定医生：</span>
                                                <span className='number'>{item.is_appoint_doctor==1 ? item.docter_name : '无'}</span>
                                            </p>
                                            <p className='pharmacist-topay'>
                                                提交时间：{item.createTime}
                                            </p>
                                        </div>)) : <Nodata type='审方'/>}
                                </div>
                            </>
                    : null}
            </div>
        )
    }
    outer = () =>{
        alert('退出登录', '确认退出登录吗？', [
            { text: '取消', onPress: () => console.log('cancel') },
            {
                text: '确认', onPress: () => {
                    localStorage.removeItem("dutyName")
                    localStorage.removeItem("dutyAvatar")
                    localStorage.removeItem("dutyId")
                    localStorage.removeItem("dutyPhone")
                    this.props.history.push("/Loginduty")
                }
            },
        ])
    }

    render() {
        const {
            count1,
            count2,
            docterPhone,
            visible2,
            time,
            time1,
            visible3,
            docter,
            tabs,
            docterAvatar,
            code,
            allNum,
            dayNum,date2,visible4,
            dutymenber
        } = this.state
        // console.log(dutymenber,'223=====');
        return (
                <div className='homeduty-outer'>
                    <div className='homeduty-top'>
                        <div className='homeduty-title'>
                            <div className='homeduty-info' onClick={this.tologin}>
                                <img src={docterAvatar != null ? docterAvatar : icon4} alt=""/>
                                <h2>
                                    {docterPhone ? docterPhone : "未登录"}
                                </h2>
                            </div>
                            {docterPhone?<h1 onClick={()=>this.outer()}>
                                <i className='iconfont icon-bianji2'/>
                                <span>退出登录</span>
                            </h1>:
                                <h1 onClick={()=>this.tologin()}>
                                    <i className='iconfont icon-bianji2'/>
                                    <span>去登录</span>
                                </h1>}
                        </div>
                        {/* {docterPhone ? (
                            <>
                                <div className='homeduty-menmder-day'>
                                    {dutymenber.length ? (
                                        <>
                                            {dutymenber.map((i)=>(
                                                <>
                                                    <span>今日值班人员：{i.name}</span>
                                                    <span>日期：{i.duty_time}</span>
                                                </>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            <span>今日值班人员：</span>
                                            <span>日期：</span>
                                        </>
                                    )}
                                    
                                </div>
                            </>
                        ) : null} */}
                        
                        <div className='homeduty-num'>
                            <div onClick={()=>this.props.history.push(`/dutyconsultation`)}>
                                <img src={icon1}/>
                                <p>已接问诊</p>
                            </div>

                            <div onClick={()=>this.props.history.push(`/dutyaccount`)}>
                                <img src={icon2}/>
                                <p>已审处方</p>
                            </div>

                            <div onClick={()=>this.props.history.push(`/dutyrefuse`)}>
                                <img src={icon6}/>
                                <p>查看拒诊</p>
                            </div>
                        </div>
                    </div>
                    <div className='homeduty-chufanghui'>

                        <Tabs
                            tabs={tabs}
                            tabBarTextStyle={{fontSize: '16px', padding: '0', color: '#333 ', height: '52px'}}
                            tabBarActiveTextColor="#1F87FF"
                            // tabBarInactiveTextColor="#FFFFFF"
                            tabBarBackgroundColor='none'
                            tabBarUnderlineStyle={{
                                border: '2px #1A73E8 solid',
                                transform: 'scaleX(0.3)',
                                bottom: '10px'
                            }}
                            renderTabBar={props => <Tabs.DefaultTabBar {...props} page={6}/>}
                        >
                            {this.renderContent}
                        </Tabs>
                    </div>

            </div>
        )
    }
}

export default withRouter(Platform)